// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-tsx": () => import("/opt/build/repo/src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-again-tsx": () => import("/opt/build/repo/src/pages/again.tsx" /* webpackChunkName: "component---src-pages-again-tsx" */),
  "component---src-pages-again-1-tsx": () => import("/opt/build/repo/src/pages/again/1.tsx" /* webpackChunkName: "component---src-pages-again-1-tsx" */),
  "component---src-pages-again-2-a-tsx": () => import("/opt/build/repo/src/pages/again/2a.tsx" /* webpackChunkName: "component---src-pages-again-2-a-tsx" */),
  "component---src-pages-again-2-b-tsx": () => import("/opt/build/repo/src/pages/again/2b.tsx" /* webpackChunkName: "component---src-pages-again-2-b-tsx" */),
  "component---src-pages-again-3-a-tsx": () => import("/opt/build/repo/src/pages/again/3a.tsx" /* webpackChunkName: "component---src-pages-again-3-a-tsx" */),
  "component---src-pages-again-3-b-tsx": () => import("/opt/build/repo/src/pages/again/3b.tsx" /* webpackChunkName: "component---src-pages-again-3-b-tsx" */),
  "component---src-pages-again-4-tsx": () => import("/opt/build/repo/src/pages/again/4.tsx" /* webpackChunkName: "component---src-pages-again-4-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

